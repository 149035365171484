import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../environments/environment';
import { pipe, Subject } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import * as XLSX from 'xlsx';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  public session_id = '';

  private dashboardSource = new Subject<boolean>();

  dashboardComp$ = this.dashboardSource.asObservable();

  sync_timeStamp:any = new Date().toLocaleString();

  chats:any = [];
  chats_preview:any = [];
  terminated_chats:any = [];

  tipo_de_muestra:any = 'Turnos obtenidos';

  filtros:any = {};

  turnos:any = [];
  locales:any = [];
  tipo_turnos:any = [];
  origen:any = [];
  status:any = [];

  categorias_destacados:any = [];

  token:any;
  httpOptions: any;

  date_range:any;
  selectedLocal:any = '';
  selectedTipoTurnos:any = 'Todos';
  selectedOrigen:any = 'Todos';
  selectedStatus:any = 'Todos';
  turno_limpio:any = 'Todos';
  rango_datos:any = 'Horas';

  getModuloEstadistica:any = [];

  callbackEmitter:any = 'Dashboard';

  api_turnos:any = {
    'Turnos obtenidos': 'filteredTurnosByType',
    'Canal obtención turnos': 'filteredTurnosByOrigin',
    'Status de los turnos': 'filteredTurnosByStatus',
    'Tiempo promedio de espera': 'filteredAverageClientWait',
    'Tiempo promedio de atención': 'filteredAverageClientAttendance',
    'Clientes en fila': 'filteredMaxClientOnLine',
    'Esperas de menos de 5 min': 'filteredAttendanceAccomplishment',
    'Respuestas NPS': 'filteredNps',
    'Puntaje NPS': 'filteredNpsScore',
    'Tasa de respuesta NPS': 'filteredNpsRate',
    'Comparacion turnos con telefono': 'filteredHasPhone',
    'Respuestas SAC': 'sacResponses',
  }

  api_sac:any = {
    'Chats': ''
  }

  constructor(private http: HttpClient, private authService: NbAuthService) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.token = token.getValue();
        this.session_id = `id-${token.getValue()}-${Date.now()}`;
      }
    });
    
    console.log(this.token)
    console.log(this.session_id)
    console.log(new HttpHeaders({ 'Content-Type': 'application/json' , 'Authorization': 'Bearer ' + this.token}))
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' , 'Authorization': 'Bearer ' + this.token})
    };
    console.log(`Running & Calling on ${environment.env_name}`);
  }

  getToken(){
    console.log(this.httpOptions.headers);
  }

  async globalFilterCallBack(){
    switch(this.callbackEmitter){
      case 'Dashboard':
        this.dashboardSource.next(null);
        break;
      default:
        break;
    }
  }

  updateGlobalFilter(date_range, selectedLocal, selectedOrigen, selectedStatus, selectedTipoTurnos){
    if(!selectedLocal) return;
    this.date_range = !date_range ? '' : date_range;
    this.selectedLocal = selectedLocal;
    this.selectedOrigen = selectedOrigen;
    this.selectedStatus = selectedStatus;
    this.selectedTipoTurnos = selectedTipoTurnos;
  }

  async updateData(){
    await this.getFiltros();
    await this.getFilteredTurnos();
  }

  async getLastMessagesByNumber(nombre:any, last_message:any){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      phone: nombre.replace('+',''),
      last_id: last_message.id
    }

    try {
      const last_messages = (await this.http.get(`${API_URL}/sac/chat-update`, httpOptions).pipe(timeout(10000), catchError(e =>{ throw Error('Timeout')})).toPromise());
      return last_messages['messages'];
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getChats(date_range = undefined){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      date_start: date_range ? new Date(date_range.start).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      date_end: date_range ? new Date(date_range.end).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
    }

    // console.log(httpOptions);

    try {
      const filteredResult = (await this.http.get(`${API_URL}/sac/${this.api_sac['Chats']}`, httpOptions).pipe(timeout(10000), catchError(e =>{ throw Error('Timeout')})).toPromise());
      this.chats_preview = filteredResult['allChats'];
      console.log(this.chats_preview);
    } catch (error) {
      console.log(error);
    }
    // console.log(this.chats);
  }

  async getTerminatedChats(date_range = undefined){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      date_start: date_range ? new Date(date_range.start).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      date_end: date_range ? new Date(date_range.end).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
    }

    try {
      const filteredResult = (await this.http.get(`${API_URL}/sac/terminatedChats`, httpOptions).pipe(timeout(10000), catchError(e =>{ throw Error('Timeout')})).toPromise());
      this.terminated_chats = filteredResult['terminatedChats'];
    } catch (error) {
      console.log(error);
    }
    // console.log(this.chats);
  }

  async getHistoryChat(phone){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      phone
    };

    try {
      const chat_history = (await this.http.get(`${API_URL}/sac/chatHistory`, httpOptions).pipe(timeout(10000), catchError(e =>{ throw Error('Timeout')})).toPromise());
      return chat_history['chat'];
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async cutChat(nombre:any){
    let httpOptions = {...this.httpOptions};
    try {
      await this.http.post(`${API_URL}/sac/cut`, {nombre: nombre.replaceAll('+','')}, httpOptions).toPromise();
    } catch (error) {
      console.log(error);
      throw Error
    }
  }

  async getFilteredTurnos(){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      date_start: this.date_range ? new Date(this.date_range.start).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      date_end: this.date_range ? new Date(this.date_range.end).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      codigo_local: this.selectedLocal ? this.selectedLocal.replace(')','').split('(')[1] : this.filtros.locales[0].replace(')','').split('(')[1],
      origen: this.selectedOrigen !== 'Todos' ? this.selectedOrigen.replace(')','').split('(')[1] : 'Sin Filtro',
      status: this.selectedStatus !== 'Todos' ? this.selectedStatus.replace(')','').split('(')[1] : 'Sin Filtro',
      tipo_fila: this.selectedTipoTurnos !== 'Todos' ? this.selectedTipoTurnos.substr(-2,1) : 'Sin Filtro',
      rango: this.rango_datos,
      turno_valido: this.turno_limpio !== 'Todos' ? this.turno_limpio : 'Sin Filtro',
    }
    console.log(httpOptions)
    const filteredResult = (await this.http.get(`${API_URL}/turnos/${this.api_turnos[this.tipo_de_muestra]}`, httpOptions).toPromise());

    this.turnos = filteredResult['turnos'];
    console.log(this.turnos);
    // console.log(this.turnos);
  }

  async getUnknownId(phone:any, date:any, text:any){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      phone: phone.replace('+',''),
      date: date.split(',')[0].split('/').reverse().join('/') + date.split(',')[1],
      text: text
    }

    try {
      return (await this.http.get(`${environment.API_URL}/sac/message-id`, httpOptions).toPromise())['id'];
    } catch (error) {
      console.error(error);
      return -1;
    }
  }

  async getRealTimeLocalData(){
    let httpOptions = {...this.httpOptions};
    return (await this.http.get(`${API_URL}/turnos/realtime`, httpOptions).toPromise())['locales'];
  }

  async getFiltros(){
    // await this.http.get(`${API_URL}/locales/`, this.httpOptions).subscribe(res => {
    //   console.log(res)
    // })
    this.locales = (await this.http.get(`${API_URL}/locales/`, this.httpOptions).toPromise())['allLocales'];
    this.tipo_turnos = (await this.http.get(`${API_URL}/turnos/tipos`, this.httpOptions).toPromise())['allTipos'];
    this.origen = (await this.http.get(`${API_URL}/turnos/origenes`, this.httpOptions).toPromise())['allOrigenes'];
    this.status = (await this.http.get(`${API_URL}/turnos/status`, this.httpOptions).toPromise())['allStatus'];

    console.log(this.locales);

    let local_names = this.locales.map(local => {
      return `${local.nombre} (${local.id})`
    })
    let tipo_turnos = this.tipo_turnos.map(tipo_turno => {
      return `${tipo_turno.nombre} (${tipo_turno.label}) (${tipo_turno.id})`
    });
    let origen = this.origen.map(origin => {
      return `${origin.tipo} (${origin.id})`
    });
    let status = this.status.map(stat => {
      if(stat.status.includes('N/A')) return null;
      return `${stat.status} (${stat.id})`
    }).filter(nonull => nonull);

    this.filtros = {
      locales: local_names,
      tipo_turnos: tipo_turnos,
      origen: origen,
      status: status,
    };
  }

  async getRawFilteredData(rangos = false){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      date_start: this.date_range ? new Date(this.date_range.start).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      date_end: this.date_range ? new Date(this.date_range.end).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      codigo_local: this.selectedLocal ? this.selectedLocal.replace(')','').split('(')[1] : this.filtros.locales[0],
      origen: this.selectedOrigen !== 'Todos' ? this.selectedOrigen.replace(')','').split('(')[1] : 'Sin Filtro',
      status: this.selectedStatus !== 'Todos' ? this.selectedStatus.replace(')','').split('(')[1] : 'Sin Filtro',
      tipo_fila: this.selectedTipoTurnos !== 'Todos' ? this.selectedTipoTurnos.substr(-2,1) : 'Sin Filtro',
      turno_valido: this.turno_limpio !== 'Todos' ? this.turno_limpio : 'Sin Filtro',
    }

    console.log(httpOptions.params)

    console.log(this.rango_datos);
    if(rangos) httpOptions.params.rango = this.rango_datos;

    console.log(this.tipo_de_muestra)
    let filteredResult = (await this.http.get(`${API_URL}/turnos/rawFilteredData`, httpOptions).toPromise())['filteredTurnos'];

    filteredResult.forEach(rango => {
      rango.data.forEach(turno => {
        if(turno.hora_ingreso) turno.hora_ingreso = new Date(turno.hora_ingreso).toLocaleString().replace(',','');
        if(turno.hora_atencion) turno.hora_atencion = new Date(turno.hora_atencion).toLocaleString().replace(',','');
        if(turno.hora_termino_atencion) turno.hora_termino_atencion = new Date(turno.hora_termino_atencion).toLocaleString().replace(',','');
        if(turno.hora_pospuesto) turno.hora_pospuesto = new Date(turno.hora_pospuesto).toLocaleString().replace(',','');
      });
    })

    return filteredResult;
  }

  async excelData(codigo_local){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      codigo_local
    }
    console.log(this.tipo_de_muestra)
    let data = (await this.http.get(`${API_URL}/sac/excelinfo`, httpOptions).toPromise())['sac_data'];

    return data;
  }

  async cargarCategoriasDestacados(){
    try {
      this.categorias_destacados = (await this.http.get(`${environment.API_URL}/sac/categorias-destacados`, this.httpOptions).toPromise())['destacados'];
      console.log(this.categorias_destacados);
    } catch (error) {
      console.error(error);
    }
  }

  async getMensajesDestacados(date_range, selected_local){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      date_start: date_range ? new Date(date_range.start).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      date_end: date_range ? new Date(date_range.end).toLocaleDateString().split('/').reverse().join('/') : 'Sin Filtro',
      codigo_local: selected_local !== 'Todos' ? selected_local.replace(')','').split('(')[1] : 'Sin Filtro',
    }

    try {
      return (await this.http.get(`${environment.API_URL}/sac/destacados`, httpOptions).toPromise())['mensajes'];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async syncChats(){
    let httpOptions = {...this.httpOptions};
    httpOptions.params = {
      timeStamp: this.sync_timeStamp.split(',')[0].split('/').reverse().join('/') + this.sync_timeStamp.split(',')[1] ,
    }
    try {
      // let data_n = (await fetch(`${environment.API_URL}/sac/sync-chats?timeStamp=${httpOptions.params.timeStamp}`, {
      //   method: 'GET',
      //   headers: { 'Content-Type': 'application/json' , 'Authorization': 'Bearer ' + this.token},
      // }));


      // let data = (await data_n.json());
      let data:any = (await this.http.get(`${environment.API_URL}/sac/sync-chats`, httpOptions).toPromise());
      console.log('CRUDO:', data);

      // let chats = JSON.parse(JSON.stringify(data));

      // data.forEach(element => {
      //   element.mensajes = JSON.parse(element.mensajes);
      // });


    //   let chats = data.reduce((acc, current) => {

    //     let i = acc.findIndex( chat => chat.nombre == current.nombre);

    //     return i === -1 ? acc.push({
    //         nombre: current.nombre,
    //         mensajes: [{
    //           id: current.id,
    //           mensaje: current.mensaje,
    //           date: current.fechahora,
    //           reply: current.reply == 1 ? true : false,
    //           sync: true,
    //           destacado: current.destacado
    //       }]
    //     }) : acc[i].mensajes.push({
    //       id: current.id,
    //       mensaje: current.mensaje,
    //       date: current.fechahora,
    //       reply: current.reply == 1 ? true : false,
    //       sync: true,
    //       destacado: current.destacado
    //   }), acc;
    // }, []);

    data = data.map(c => {
      c.nombre = `+${c.nombre}`;
      return c;
    });

      this.sync_timeStamp = new Date().toLocaleString();
      return data;

      // chats = chats['mensajes'].map(c => {
      //   c.mensajes = c.mensajes;
      //   return c;
      // })

      // console.log(mensajes);

      // console.log(chats);

      return [];
      // return chats;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async destacarMensaje(msg, high_id){
    let httpOptions = {...this.httpOptions};
    try {
      let date = `${msg.date.split(',')[0].split('/').reverse().join('/')}${msg.date.split(',')[1]}`;
      let response = await this.http.put(`${environment.API_URL}/sac/destacar`,{msg_id:msg.id, high_id, phone: msg.phone.replace('+',''), date: date}, httpOptions).toPromise();
      console.log(response);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Export an excel file with the json structure given
   * @param json Json Structure and info to be exported Key as head and Value as tablebody
   * @param sheetName Name of the sheet that will contain the info
   * @param fileName Name of the excel file to be exported
   */
  exportXLS(json, sheetName, fileName, is_multiple = false){
    const EXCEL_EXTENSION = '.xlsx';
    const workbook = {
      Sheets: {},
      SheetNames:[]
    };

    if(is_multiple){
      json.forEach(rango => {
        const worksheet = XLSX.utils.json_to_sheet(rango.data);
        workbook.Sheets[`${this.rango_datos} ${rango.rango}`] = worksheet;
        workbook.SheetNames.push(this.rango_datos+' '+rango.rango);
      });
    }else{
      const worksheet = XLSX.utils.json_to_sheet(json);
  
      workbook.Sheets[`${sheetName}`] = worksheet;
      workbook.SheetNames.push(sheetName);
    }


    XLSX.writeFile(workbook, fileName+EXCEL_EXTENSION);
  }


  // Estadisticas

  async getGenteFilaMes (mes, year, fila) {
    try {
      return await this.http.get(`${environment.API_URL}/estadisticas/persona/${mes}/${year}/${fila}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  exportXLSFilaMes(json, sheetName, fileName){
    const EXCEL_EXTENSION = '.xlsx';

    const data = XLSX.utils.json_to_sheet(json);

    const libro = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(libro, data, sheetName);

    XLSX.writeFile(libro, fileName+EXCEL_EXTENSION);
  }

  async getModuloMes (mes, year, local, fila) {
    try {
      this.getModuloEstadistica = await this.http.get(`${environment.API_URL}/estadisticas/modulo/${mes}/${year}/${local}/${fila}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      this.getModuloEstadistica = {};
    }
  }

  async getModuloDia (mes, year, local, dia, fila) {
    try {
      this.getModuloEstadistica = await this.http.get(`${environment.API_URL}/estadisticas/modulo/${mes}/${year}/${local}/${dia}/${fila}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      this.getModuloEstadistica = {};
    }
  }

  async getModuloHora (mes, year, local, dia, desde, hasta, fila) {
    try {
      this.getModuloEstadistica = await this.http.get(`${environment.API_URL}/estadisticas/modulo/${mes}/${year}/${local}/${dia}/${desde}/${hasta}/${fila}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      this.getModuloEstadistica = {};
    }
  }

  async getModuloCantidadMes (mes, year, local, fila) {
    try {
      this.getModuloEstadistica = await this.http.get(`${environment.API_URL}/estadisticas/modulocant/${mes}/${year}/${local}/${fila}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      this.getModuloEstadistica = {};
    }
  }

  async getModuloCantidadDia (mes, year, local, dia, fila) {
    try {
      this.getModuloEstadistica = await this.http.get(`${environment.API_URL}/estadisticas/modulocant/${mes}/${year}/${local}/${fila}/${dia}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      this.getModuloEstadistica = {};
    }
  }

  async getModuloCantidadHora (mes, year, local, dia, desde, hasta, fila) {
    try {
      this.getModuloEstadistica = await this.http.get(`${environment.API_URL}/estadisticas/modulocant/${mes}/${year}/${local}/${fila}/${dia}/${desde}/${hasta}`, this.httpOptions).toPromise();
    } catch (error) {
      console.error(error);
      this.getModuloEstadistica = {};
    }
  }
}
