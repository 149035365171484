import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../../_services/provider.service';

@Component({
  selector: 'ngx-global-filter',
  templateUrl: './global-filter.component.html',
  styleUrls: ['./global-filter.component.scss']
})
export class GlobalFilterComponent implements OnInit {

  localList:any = [];
  origenList:any = [];
  zonaList:any = [];
  statusList:any = [];
  tipoTurnoList:any = [];

  date_range:any;
  selectedLocal:any = '';
  selectedOrigen:any = 'Todos';
  selectedZona:any = 'Todos';
  selectedStatus:any = 'Todos';
  selectedTipoTurno:any = 'Todos';

  constructor(public provService : ProviderService) {}

  async ngOnInit(){
    await this.fillFiltros();
  }


  async fillFiltros(){
    await this.provService.getFiltros();
    console.log(this.provService.filtros)
    this.localList = this.provService.filtros.locales;
    this.origenList = this.provService.filtros.origen;
    this.statusList = this.provService.filtros.status;
    this.tipoTurnoList = this.provService.filtros.tipo_turnos;

    this.date_range = this.provService.date_range;
    this.selectedLocal = this.provService.selectedLocal || this.localList[0];
    this.selectedOrigen = this.provService.selectedOrigen;
    this.selectedStatus = this.provService.selectedStatus;
    this.selectedTipoTurno = this.provService.selectedTipoTurnos;
  }

  async filter(){
    console.log(this.date_range)
    if(this.date_range) if(!this.date_range.end) return
    this.provService.updateGlobalFilter(this.date_range, this.selectedLocal, this.selectedOrigen, this.selectedStatus, this.selectedTipoTurno);
    await this.provService.updateData();
    this.provService.globalFilterCallBack();
  }

  resetFilters(){
    this.date_range = '';
    this.selectedLocal = this.localList[0];
    this.selectedOrigen = 'Todos';
    this.selectedZona = 'Todos';
    this.selectedStatus = 'Todos';
    this.selectedTipoTurno = 'Todos';
  }

  async validDateFilter(event){
    if(event.start && event.end){
      setTimeout(async () => {
        await this.filter();
      }, 25);
    }
  }
}
