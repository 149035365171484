<ngx-custom-spinner
    *ngIf="loader"
    class="router-child-adjustment"
></ngx-custom-spinner>
<div class="main-wrapper">
    <div class="show-channel"></div>
    <div class="filterWrapper">
        <div class="data-range-container"  >
            <h5>Visualizar la data por: [ {{provService.rango_datos}} ]</h5>
            <div class="data-range">
                <!-- <button (click)="changeDataRange('Local')" >LOCALES</button> -->
                <button (click)="changeDataRange('Horas')" >HORAS</button>
                <button (click)="changeDataRange('Dias')" >DIAS</button>
                <button (click)="changeDataRange('Semanas')" >SEMANAS</button>
                <button (click)="changeDataRange('Mes')" >MES</button>
            </div>
        </div>
        <ngx-global-filter></ngx-global-filter>
        <div  class="toShow-container">
            <ul>
                <li>
                    <p>Tipo de data a mostrar</p>
                    <select [(ngModel)]="provService.tipo_de_muestra" placeholder="Ver Turnos" class="iconicInput icon-base" (change)="updateData()">
                        <option value="Turnos obtenidos">Turnos obtenidos</option>
                        <option value="Canal obtención turnos">Canal obtención turnos</option>
                        <option value="Status de los turnos">Status de los turnos</option>
                        <option value="Tiempo promedio de espera">Tiempo promedio de espera</option>
                        <!-- <option value="Tiempo promedio de atención">Tiempo promedio de atención</option> -->
                        <option value="Clientes en fila">Clientes en fila</option>
                        <!-- <option value="Esperas de menos de 5 min">Esperas de menos de 5 min</option> -->
                        <!-- <option value="Puntaje NPS">Puntaje NPS</option>
                        <option value="Respuestas NPS">Respuestas NPS</option>
                        <option value="Tasa de respuesta NPS">Tasa de respuesta NPS</option> -->
                        <!-- <option value="Comparacion turnos con telefono">Comparación turnos con teléfono</option>
                        <option value="Respuestas SAC">Cantidad de interacciones SAC</option> -->
                    </select>
                </li>
                <!-- <li>
                    <p>Validez del turno</p>
                    <select [(ngModel)]="provService.turno_limpio" placeholder="Todos los turnos" class="iconicInput icon-base" (change)="updateData()">
                        <option value="Todos">Todos los turnos</option>
                        <option value="1">Turnos Validos</option>
                        <option value="0">Turnos Invalidos</option>
                    </select>
                </li> -->
            </ul>
        </div>
    </div>
    <div class="resultsGraficos" style="min-height: 1200px; position: relative;">
        <p *ngIf="error" class="error-message" style="font-weight: bold; text-align: center;">No hay retorno de información con los filtros seleccionados</p>
        <div class="buttonActionWrapper" >
            <button class="buttonAction"   (click)="changeVisualization()" >
                {{percentage ? '%' : 'Q'}}
            </button>
            <!-- <button (click)="exportRawData()" class="buttonAction"  >
                <img src="../../../assets/images/excel.png" width="45">
            </button> -->
            <!-- <button (click)="exportRawFilteredData()" class="buttonAction"  >
                <img src="../../../assets/images/excel.png" width="45">
                <img src="assets/images/funnel-outline.svg" class="funnel-icon"/>
            </button> -->
        </div>
        <div class="graficoDetalle charts noselect" id="grafico5" style="position: relative;">
            <h5 style="margin-block: 2.5rem;">{{percentage ? 'Porcentaje de ' + descripcion : 'Cantidad de ' + descripcion}}</h5>
            <div style="min-height: 750px;" echarts [options]="optionsBar" class="echart"></div>
        </div>
    </div>
</div>