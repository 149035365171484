import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from "ngx-mqtt";
import { Observable } from "rxjs";


@Injectable()
export class EventMqttService {

  constructor(private mqttService: MqttService) {}

  topic_update:any;
  topic_sync:any;
  topic_keep_alive:any;

  topic(topic: string): Observable<IMqttMessage> {
    let topicName = `chatbot/dashboard/${topic}`;
    return this.mqttService.observe(topicName, {qos: 2});
  }

  publish(topic: string, message: string){
    return this.mqttService.publish(`chatbot/dashboard/${topic}`, message);
  }

  startDetection(){
    console.log('Tracking mqtt events!');
    this.mqttService.onOffline.subscribe(event => {
      console.log('MQTT OFFLINE', event);
    });
    this.mqttService.onReconnect.subscribe(event => {
      console.log('MQTT RECONNECT', event);
    });
  }
}