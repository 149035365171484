<div class="header-container">
  <div class="logo-container">
    <!-- <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a> -->
    <a class="logo" href="#" (click)="navigateHome()"><img width="65" src="./../../../../assets/images/logo.png"></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action  class="user-action" *nbIsGranted="['view', 'user']" >
      <!-- [nbContextMenu]="userMenu" -->
      <!-- [onlyPicture]="userPictureOnly" -->
      <!-- [picture]="user?.picture" -->
      <!-- <nb-user
        [name]="user?.Nombre"
        [showInitials]="false"
      >
      </nb-user> -->
      <p class="nombre">{{user.Nombre}}</p>
      <a style="display: flex; font-size: 1rem; margin-top: 3px; align-items: center;" href class="nav-link" (click)="logout()">
        Salir
        <nb-icon icon="arrow-circle-right-outline"></nb-icon>
      </a>
    </nb-action>
  </nb-actions>
</div>
