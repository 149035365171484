export const stackedChart:any = (data_chart, percentage = false, color, legenda) => {
    let structured_data = data_chart.yAxis.map(type => {
        return {
            name: type.name,
            type: 'bar',
            // stack: 'total',
            label: {
                show: true,
                formatter: percentage ? "{c}%" : "{c}"
            },
            emphasis: {
                focus: 'series'
            },
            data: type.values
        }
    })

    const data = data_chart.yAxis.map(y => {
        return y.values
    })

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        color: color,
        // color: ['#7ec0ef', '#2ECC71'],
        // legend: {
        //     padding: 0,
        // },
        grid: {
            left: '3%',
            right: '4%',
            height: '700',
            bottom: '3%',
            containLabel: true,
            top: '7%',
        },
        xAxis: {
            type: 'category',
            data: data_chart.xAxis,
            axisLabel: {
                rotate: 90,
            }
        },
        yAxis: {
            type: 'value',
            max: percentage ? 100 : null,
            axisLabel: {
                formatter: percentage  ? "{value}%" : "{value}",
            }
        },
        // series: structured_data
        series: [{
            name: legenda,
            type: 'bar',
            data
        }]
    }
}