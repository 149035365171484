import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProviderService } from '../../_services/provider.service';

@Component({
  selector: 'ngx-tiempo-real',
  templateUrl: './tiempo-real.component.html',
  styleUrls: ['./tiempo-real.component.scss']
})
export class TiempoRealComponent implements OnInit {

  default_data:any = [];

  loader:boolean = false;
  update_timer:any;
  locales:any = [];
  next_update:any = 0;
  time_left:any = '00:00';
  delay_time:any = 1000 * 60 * 1;
  delay_timer:any;
  last_update:any = '';

  constructor(public provService : ProviderService, private router: Router) { }

  async ngOnInit() {
    this.loader = true;
    if(!Object.keys(this.provService.filtros).length) await this.provService.getFiltros();
    console.log(this.provService.filtros.locales)
    this.default_data = this.provService.filtros.locales.map(local => {
      return {
        nombre_local: local.split(' (')[0],
        en_fila: '0',
        espera_promedio: 0.0,
        espera_maxima: 0.0
      }
    });
    await this.update();
    this.next_update = Date.now() + this.delay_time;
    this.nextUpdate();
    this.syncUpdate();
    this.loader = false;
  }

  async syncUpdate(){
    clearInterval(this.update_timer);
    this.update_timer = setInterval(async () => {
      this.next_update = Date.now() + this.delay_time;
      await this.update();
    }, this.delay_time);
  }

  async update(){
    let locales:any = (await this.provService.getRealTimeLocalData());
    console.log(locales);
    this.locales = this.default_data.map(data => {
      let local = locales.find(local => local.nombre_local.split('Salcobrand ')[1] === data.nombre_local);
      if(local){
        data.codigo = local.codigo_local;
        data.en_fila = local.en_fila;
        data.espera_promedio = local.espera_promedio;
        data.espera_maxima = local.espera_maxima
        return data;
      }
      return null
    }).filter(l => l);
    this.last_update = new Date().toLocaleTimeString();
  }

  ngOnDestroy(){
    clearInterval(this.update_timer);
    clearInterval(this.delay_timer);
  }

  stateColor(local){
    let state_color = '--salcobrand-rojo';
    if(local.espera_promedio >= 0 && local.espera_promedio < 5 && (local.nombre_local !== 'Cantagallo')){
      state_color = '--salcobrand-verde';
    }else if(local.espera_promedio >= 5 && local.espera_promedio < 10 && (local.nombre_local !== 'Cantagallo')){
      state_color = '--salcobrand-amarillo';
    }else if (local.espera_promedio < 0 || local.nombre_local == 'Cantagallo'){
      state_color = '--salcobrand-gris';
    }
    return `var(${state_color})`;
  }


  nextUpdate(){
    this.delay_timer = setInterval(() => {
      let time_left = (this.next_update - Date.now()  < 0 ? 0 : this.next_update - Date.now()) / 1000;
      const minute = Math.floor(time_left / 60);
      const seconds = Math.floor(time_left % 60);
      this.time_left =  `${minute < 10 ? '0'+minute:minute}:${seconds < 10 ? '0'+seconds:seconds}`;
    }, 1000);
  }
}