<ngx-custom-spinner
    *ngIf="loader"
    class="router-child-adjustment"
></ngx-custom-spinner>
<div class="main-wrapper">
    <div class="show-channel"></div>
    <div class="filterWrapper">
        <div class="data-range-container"  >
            <h5>Filtros</h5>
            <div class="data-range">
                <h4 class="data-range-text">Filtro: </h4>
                <select [(ngModel)]="selectedOpcion" placeholder="Año" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let opcion of opciones" value="{{opcion.value}}">{{opcion.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Año: </h4>
                <select [(ngModel)]="selectedYear" placeholder="Año" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let year of years" value="{{year.value}}">{{year.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Mes: </h4>
                <select [(ngModel)]="selectedMes" placeholder="Mes" class="iconicInput icon-base" (change)="obtenerDiasMes(); filtro();">
                    <option *ngFor="let month of months" value="{{month.value}}">{{month.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Día: </h4>
                <select [(ngModel)]="selectedDay" placeholder="Mes" class="iconicInput icon-base" (change)="changeDays(); filtro();">
                    <option *ngFor="let day of days" value="{{day.value}}">{{day.label}}</option>
                </select>
            </div>
            <div class="data-range" *ngIf="selectedDay != 0">
                <h4 class="data-range-text">Hora Desde: </h4>
                <select [(ngModel)]="selectedDesde" placeholder="Mes" class="iconicInput icon-base" (change)="changeDesde();filtro();">
                    <option *ngFor="let hora of horas" value="{{hora.value}}">{{hora.label}}</option>
                </select>
            </div>
            <div class="data-range" *ngIf="selectedDesde != 0">
                <h4 class="data-range-text">Hora Hasta: </h4>
                <select [(ngModel)]="selectedHasta" placeholder="Mes" class="iconicInput icon-base">
                    <option *ngFor="let hora of horas" value="{{hora.value}}">{{hora.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Departamento: </h4>
                <select [(ngModel)]="selectedDireccion" placeholder="Departamento" class="iconicInput icon-base">
                    <option *ngFor="let departamento of direcciones" value="{{departamento.value}}">{{departamento.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Fila: </h4>
                <select [(ngModel)]="selectedFila" placeholder="Fila" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let fila of filas" value="{{fila.value}}">{{fila.label}}</option>
                </select>
            </div>

            <div class="data-range">
                <button (click)="filtroReinicio()">Reiniciar Filtros</button>
            </div>
        </div>
    </div>

    <div class="resultsGraficos" style="min-height: 1200px; position: relative;">
        <div class="graficoDetalle charts noselect" id="grafico5" style="position: relative;">

            <h2 class="table__title" *ngIf="selectedDay == 0">"{{direcciones[0].label}}" {{months[selectedMes - 1].label}} {{selectedYear}} </h2>
            <h2 class="table__title" *ngIf="selectedDay != 0 && selectedDesde==0">"{{direcciones[0].label}}" {{days[selectedDay].label}} {{months[selectedMes - 1].label}} {{selectedYear}} </h2>
            <!-- <h2 class="table__title" *ngIf="selectedDesde != 0">"{{direcciones[0].label}}" {{months[selectedMes - 1].label}} {{selectedYear}} </h2> -->

            <!-- <button (click)="exportarXLSRenovar()" class="excel">
                <p class="excel_text">Descargar Excel Modulo Renovar Licencia</p>
                <img _ngcontent-gyl-c129="" class="excel_img" src="../../../assets/images/excel.png" width="45">
            </button> -->

            <div class="graficoDetalle charts noselect" id="grafico5" style="position: relative;">
                <h5 style="margin-block: 2.5rem;">"{{filas[selectedFila -1 ].label}}" {{opciones[selectedOpcion -1].label}}</h5>
                <div style="min-height: 750px;" echarts [options]="optionsBar" class="echart"></div>
            </div>
        </div>
    </div>
</div>