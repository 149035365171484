export const stackedChart:any = (data_chart, percentage = false) => {
    let structured_data = data_chart.yAxis.map(type => {
        return {
            name: type.name,
            type: 'bar',
            stack: 'total',
            label: {
                show: true,
                formatter: percentage ? "{c}%" : "{c}"
            },
            emphasis: {
                focus: 'series'
            },
            data: type.values
        }
    })
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
        },
        color: ['#00ff00', '#d11919','#fa0','#e4e11a'],
        legend: {
            padding: 0,
        },
        grid: {
            left: '3%',
            right: '4%',
            height: '700',
            bottom: '3%',
            containLabel: true,
            top: '7%',
        },
        xAxis: {
            type: 'category',
            data: data_chart.xAxis,
            axisLabel: {
                rotate: 90,
            }
        },
        yAxis: {
            type: 'value',
            max: percentage ? 100 : null,
            axisLabel: {
                formatter: percentage  ? "{value}%" : "{value}",
            }
        },
        series: structured_data
    }
}