<ngx-custom-spinner
    *ngIf="loader"
    class="router-child-adjustment"
></ngx-custom-spinner>
<div class="main-wrapper">
    <div class="show-channel"></div>
    <div class="filterWrapper">
        <div class="data-range-container"  >
            <h5>Filtros</h5>
            <div class="data-range">
                <h4 class="data-range-text">Mes: </h4>
                <select [(ngModel)]="selectedMes" placeholder="Mes" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let month of months" value="{{month.value}}">{{month.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Año: </h4>
                <select [(ngModel)]="selectedYear" placeholder="Año" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let year of years" value="{{year.value}}">{{year.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Departamento: </h4>
                <select [(ngModel)]="selectedDireccion" placeholder="Departamento" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let departamento of direcciones" value="{{departamento.value}}">{{departamento.label}}</option>
                </select>
            </div>
            <div class="data-range">
                <h4 class="data-range-text">Fila: </h4>
                <select [(ngModel)]="selectedFila" placeholder="Fila" class="iconicInput icon-base" (change)="filtro()">
                    <option *ngFor="let fila of filas" value="{{fila.value}}">{{fila.label}}</option>
                </select>
            </div>

            <!-- <div class="data-range">
                <button (click)="filtro()">Filtrar</button>
            </div> -->
        </div>
    </div>

    <div class="resultsGraficos" style="min-height: 1200px; position: relative;">
        <!-- <div class="buttonActionWrapper" >
            
        </div> -->
        <div class="graficoDetalle charts noselect" id="grafico5" style="position: relative;">

            <h2 class="table__title">Tiempo por persona de "{{direcciones[selectedDireccion-1].label}}" en "{{filas[selectedFila - 1].label}}" {{months[selectedMes - 1].label}} {{selectedYear}} </h2>

            <button (click)="exportarXLS()" class="excel">
                <p class="excel_text">Descargar Excel</p>
                <img _ngcontent-gyl-c129="" class="excel_img" src="../../../assets/images/excel.png" width="45">
            </button>

            <table class="table">
                <thead>
                    <th>Nombre</th>
                    <th>RUT</th>
                    <th>Inicio Atención</th>
                    <th>Termino Atención</th>
                    <th>Tiempo Tramite</th>
                    <th>Ultima Atención</th>
                </thead>
                <tbody>
                    <tr *ngFor="let persona of data">
                        <td>{{persona.nombre}}</td>
                        <td>{{formatearRut(persona.identificador_cliente)}}</td>
                        <td>{{formatearFecha(persona.hora_ingreso)}}</td>
                        <td>{{formatearFecha(persona.hora_termino_atencion)}}</td>
                        <td>{{persona.tiempoPromedio}} Minutos</td>
                        <td>{{persona.modulo}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>