import { Component, OnDestroy, OnInit } from '@angular/core';
import {NbDialogService, NbMenuService } from '@nebular/theme';
import { ProviderService } from '../../_services/provider.service';
import { ConfirmDialogComponent } from './modal/confirm-dialog/confirm-dialog.component';
import { EventMqttService } from '../../_services/event-mqtt.service';
import { IMqttMessage } from "ngx-mqtt";
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ngx-sac',
  templateUrl: './sac.component.html',
  styleUrls: ['./sac.component.scss']
})
export class SacComponent implements OnInit, OnDestroy{

  current_chat:any = {}
  messages: any[] = [];
  to_sync_messages:any = [];

  updateTimer:any;

  filter_type:any = 'Todos';
  search_name:any = '';

  date_range:any;

  popup_audio:any;

  selected_destacado:any = -1;

  highlited_list:any = [];


  constructor(public provService: ProviderService, private dialogService: NbDialogService, private eventMqtt: EventMqttService, private nbMenuService: NbMenuService){
    Notification.requestPermission().then(function(result) {
      console.log(result);
    });
    this.popup_audio = new Audio('../../assets/sounds/sac_popup.mp3');
    if(!this.provService.categorias_destacados.length) this.provService.cargarCategoriasDestacados();
    this.nbMenuService.onItemClick().pipe(
      filter(({ tag }) => tag === 'highlited_menu'),
      map(({ item: { title } }) => title)).subscribe(title => {
        this.destacarMensaje(+title.split(' ')[0]);
      });
      console.log('Unsubscribing');
      this.eventMqtt.topic_sync?.unsubscribe();
      this.eventMqtt.topic_update?.unsubscribe();
      this.subscribeToTopic();
      this.eventMqtt.startDetection();

    }
    ngOnDestroy(): void {}

  async ngOnInit(){
    if(!this.provService.chats_preview.length) await this.provService.getChats();

    this.highlited_list = this.provService.categorias_destacados.map(categoria => {
      return {title: `${categoria.id} - ${categoria.nombre}`};
    });

    this.current_chat = this.provService.chats_preview[0] || this.chatUiMessageStructure('',false, true, [], 'Sin Mensajes', '2022-1-1 00:00:00', -1);
    if(this.provService.chats_preview.length) {
      this.provService.chats_preview[0].active = true;
      this.loadCurrentChat();
    }
    // console.log(this.provService.chats);
    // this.updateChats();
    setInterval(() => {
      this.chatSync();
    }, 1 * 60 * 1000);
  }

  async chatSync(){
    let new_chats:any = await this.provService.syncChats();

    let notify:boolean = false;
    let notify_chat = null;


    if(!new_chats.length) return;

    for(let i = 0; i < new_chats.length; i++){
      let chat_index = this.provService.chats.findIndex(c => c.nombre === new_chats[i].nombre);

      let formatted_fecha = new Date(new_chats[i].fechahora).toLocaleString();
      formatted_fecha = formatted_fecha.split(',')[0].split('/').reverse().join('-')+formatted_fecha.split(',')[1];

      let formatted_msg = this.chatMessageAPIStructure(new_chats[i].mensaje, new_chats[i].reply == 1 ? true : false, true, formatted_fecha);

      if(chat_index !== -1){
        let chat = this.provService.chats[chat_index];
        let msg_exists = chat.user_chat.find(msg => {

          if(msg.id === new_chats[i].id){
            console.log('ID',new_chats[i], msg)
            return true;
          }

          if(msg.id.toString().includes('id-') || msg.id == '-1'){
            if(new_chats[i].reply === 1){
              console.log('MENSAJE',new_chats[i], msg)
              return new_chats[i].mensaje === msg.mensaje;
            }
          }
        });

        console.log(msg_exists);
        if(msg_exists) continue;
        console.log(new_chats[0]);

        notify = true;
        notify_chat = new_chats[i];
        let chat_preview_index = this.provService.chats_preview.findIndex(c => c.nombre === new_chats[i].nombre);
        let chat_preview = this.provService.chats_preview[chat_preview_index];

        chat.user_chat.push(formatted_msg);

        chat_preview.mensaje = new_chats[i].mensaje;
        chat_preview.fecha = formatted_fecha;


        if(this.current_chat.nombre === new_chats[i].nombre){
            this.messages.push(this.chatUiMessageStructure(new_chats[i].mensaje, new_chats[i].reply == 1 ? true : false, true, [], new_chats[i].nombre, formatted_fecha, new_chats[i].id, new_chats[i].destacado));
        }else{
          chat_preview.new++;
        }
        this.provService.chats.unshift(this.provService.chats.splice(chat_index, 1)[0]);
        this.provService.chats_preview.unshift(this.provService.chats_preview.splice(chat_preview_index, 1)[0]);
      }else{
        notify = true;
        notify_chat = new_chats[i];
        let chat = await this.provService.getHistoryChat(new_chats[i].nombre.replace('+',''));

        let chat_preview = this.provService.chats_preview.find(chat => new_chats[i] === chat.nombre);

        if(chat){
          chat.score = null;
          this.provService.chats.unshift(chat);
        }else{
          this.provService.chats.unshift({
            nombre: new_chats[i],
            score: null,
            user_chat: [formatted_msg],
          });
        }

        if(chat_preview){
          chat_preview.new++;
        }else{
          this.provService.chats_preview.unshift({
            fecha: formatted_fecha,
            id: new_chats[i].id,
            mensaje: new_chats[i].mensaje,
            new: 1,
            nombre: new_chats[i].nombre,
            active: false
          })
        }
      }
    }

    if(notify){
      this.popup_audio.play();
      new Notification(`Nuevo Mensaje de ${notify_chat.nombre}`, {
        body: notify_chat.mensaje.length > 25 ? notify_chat.mensaje.substr(0, 30)+'...' : notify_chat.mensaje,
        icon: "../../assets/images/salcobrand.svg"
      });
    }

    // new_chats.forEach(async new_chat => {
    //   let chat_index = this.provService.chats.findIndex(c => c.nombre === new_chat.nombre);

    //   if(chat_index !== -1){
    //     let chat = this.provService.chats[chat_index];

    //     new_chat.mensajes = new_chat.mensajes.filter(message => !chat.user_chat.find(m => m.id === message.id || (m.mensaje === message.mensaje && (m.id == -1 || !isNaN(m.id)))));

    //     if(new_chat.mensajes.length){
    //       notify = true;
    //       notify_chat = new_chat;
    //       let chat_preview_index = this.provService.chats_preview.findIndex(c => c.nombre === new_chat.nombre);
    //       let chat_preview = this.provService.chats_preview[chat_preview_index];

    //       chat.user_chat.push(...new_chat.mensajes);

    //       chat_preview.mensaje = new_chat.mensajes[new_chat.mensajes.length - 1].mensaje;
    //       chat_preview.fecha = new_chat.mensajes[new_chat.mensajes.length - 1].date;


    //       if(this.current_chat.nombre === new_chat.nombre){

    //         new_chat.mensajes.forEach(message => {
    //           this.messages.push(this.chatUiMessageStructure(message.mensaje, message.reply, message.sync, [], message.from, message.date, message.id, message.destacado));
    //         });
    //       }else{
    //         chat_preview.new += new_chat.mensajes.length;
    //       }
    //       this.provService.chats.unshift(this.provService.chats.splice(chat_index, 1)[0]);
    //       this.provService.chats_preview.unshift(this.provService.chats_preview.splice(chat_preview_index, 1)[0]);
    //     }
    //   }else{
    //     notify = true;
    //     notify_chat = new_chat;
    //     let chat = await this.provService.getHistoryChat(new_chat.nombre.replace('+',''));

    //     let chat_preview = this.provService.chats_preview.find(chat => new_chat === chat.nombre);

    //     if(chat){
    //       chat.score = null;
    //       this.provService.chats.unshift(chat);
    //     }else{
    //       this.provService.chats.unshift({
    //         nombre: new_chat,
    //         score: null,
    //         user_chat: [...new_chat.mensajes],
    //       });
    //     }

    //     if(chat_preview){
    //       chat_preview.new = new_chat.mensajes.length;
    //     }else{
    //       this.provService.chats_preview.unshift({
    //         fecha: new_chat.mensajes[new_chat.mensajes.length - 1].date,
    //         id: new_chat.mensajes[new_chat.mensajes.length - 1].id,
    //         mensaje: new_chat.mensajes[new_chat.mensajes.length - 1].mensaje,
    //         new: new_chat.mensajes.length,
    //         nombre: new_chat.nombre,
    //         active: false
    //       })
    //     }
    //   }
    // });

    // if(notify){
    //   this.popup_audio.play();
    //   new Notification(`Nuevo Mensaje de ${notify_chat.nombre}`, {
    //     body: notify_chat.mensajes[notify_chat.mensajes.length -1].mensaje.length > 25 ? notify_chat.mensajes[notify_chat.mensajes.length -1].mensaje.substr(0, 30)+'...' : notify_chat.mensajes[notify_chat.mensajes.length -1].mensaje,
    //     icon: "../../assets/images/salcobrand.svg"
    //   });
    // }

  }

  switchDestacado(data:any){
    this.selected_destacado = data;
  }

  async destacarMensaje(high_id:any){
    let id = isNaN(high_id) ? 0 : high_id;
    if(await this.provService.destacarMensaje(this.selected_destacado, id)){
      this.messages.find(msg => msg.customMessageData.id === this.selected_destacado.id).customMessageData.destacado = id;
      this.provService.chats.find(chat => chat.nombre === this.current_chat.nombre).user_chat.find(chat => chat.id === this.selected_destacado.id).destacado = id;
    }
  }

  async updateChatsByNumber(msg:any){
    if(msg.session_id){
      if(msg.session_id === this.provService.session_id) return;
    }

    if(!msg.saliente && !isNaN(msg.body)) return;

    let formatted_fecha = msg.date.split(',')[0].split('/').reverse();
    formatted_fecha = formatted_fecha.join('-');

    let formatted_msg = this.chatMessageAPIStructure(msg.body, msg.saliente ? true : false, true, `${formatted_fecha}${msg.date.split(',')[1]}`);

    let chat_index = this.provService.chats.findIndex(chat => chat.nombre === msg.from);

    if(chat_index !== -1){
      let chat_preview_index = this.provService.chats_preview.findIndex(preview => preview.nombre === this.provService.chats[chat_index].nombre);
      let chat = this.provService.chats[chat_index];
      let chat_preview = this.provService.chats_preview[chat_preview_index];
      let messages = await this.provService.getLastMessagesByNumber(chat.nombre, [...chat.user_chat].reverse().find(c => !c.reply && c.id > 0));

      if(msg.saliente){
        if(this.current_chat.nombre === msg.from){
          this.messages.push(this.chatUiMessageStructure(formatted_msg.mensaje, formatted_msg.reply, formatted_msg.sync, [], 'Tu', formatted_msg.date, -1));
          chat.user_chat.push(formatted_msg);
        }else{
          chat.user_chat.push(formatted_msg);
        }
        chat_preview.mensaje = formatted_msg.mensaje;
        chat_preview.fecha = formatted_msg.date;
      }

      if(messages.length){
        chat.user_chat.push(...messages)

        chat_preview.mensaje = messages[messages.length - 1].mensaje;
        chat_preview.fecha = messages[messages.length - 1].date;

        if(this.current_chat.nombre === msg.from){
          messages.forEach(message => {
            this.messages.push(this.chatUiMessageStructure(message.mensaje, message.reply, message.sync, [], message.from, message.date, message.id));
          });
        }else{
          chat_preview.new += messages.length;
        }
      }
      this.provService.chats.unshift(this.provService.chats.splice(chat_index, 1)[0]);
      this.provService.chats_preview.unshift(this.provService.chats_preview.splice(chat_preview_index, 1)[0]);
    }else{
      let chat = await this.provService.getHistoryChat(msg.from.replace('+',''));

      let chat_preview = this.provService.chats_preview.find(chat => msg.from === chat.nombre);

      if(chat){
        chat.score = null;
        this.provService.chats.unshift(chat);
      }else{
        this.provService.chats.unshift({
          nombre: msg.from,
          score: null,
          user_chat: [formatted_msg],
        });
      }
      if(chat_preview){
        chat_preview.new++;
      }else{
        this.provService.chats_preview.unshift({
          fecha:formatted_msg.date,
          id: -1,
          mensaje:formatted_msg.mensaje,
          new: 1,
          nombre: chat.nombre,
          active: false
        })
      }
    }
    this.popup_audio.play();

    new Notification(`Nuevo Mensaje de ${msg.from}`, {
      body: msg.body.length > 25 ? msg.body.substr(0, 30)+'...' : msg.body,
      icon: "../../assets/images/salcobrand.svg"
    });
  }

  async updateChatsByName(msg:any){
    if(msg.session_id){
      if(msg.session_id === this.provService.session_id) return;
    }
    let formatted_fecha = msg.date.split(',')[0].split('/').reverse();
    // [formatted_fecha[1], formatted_fecha[2]] = [formatted_fecha[2], formatted_fecha[1]];
    formatted_fecha = formatted_fecha.join('-');

    //Revisar
    let formatted_msg = this.chatMessageAPIStructure(msg.body, msg.saliente ? true : false, true, `${formatted_fecha}${msg.date.split(',')[1]}`);
    if(!msg.saliente) formatted_msg.id = await this.provService.getUnknownId(msg.from, msg.date, msg.body);


    const to_update_chat_index = this.provService.chats.findIndex((chat:any) => chat.nombre === msg.from);
    const to_update_preview_index = this.provService.chats_preview.findIndex((chat:any) => chat.nombre === msg.from);

    if(to_update_chat_index !== -1){
      let to_update_chat = this.provService.chats[to_update_chat_index];
      let to_update_preview = this.provService.chats_preview[to_update_preview_index];

      to_update_chat.user_chat.push(formatted_msg);
      to_update_preview.mensaje = formatted_msg.mensaje;
      to_update_preview.fecha = formatted_msg.date;

      this.provService.chats.unshift(this.provService.chats.splice(to_update_chat_index, 1)[0]);
      this.provService.chats_preview.unshift(this.provService.chats_preview.splice(to_update_preview_index, 1)[0]);

      if(this.current_chat.nombre === msg.from){
        this.messages.push(this.chatUiMessageStructure(formatted_msg.mensaje, formatted_msg.reply, formatted_msg.sync, [], formatted_msg.reply ? 'Tu' : msg.from, formatted_msg.date, !msg.saliente ? formatted_msg.id : -1));
      }else{
        if(!msg.saliente) to_update_preview.new++;
      }

    }else{

      let chat = await this.provService.getHistoryChat(msg.from.replace('+',''));

      let preview = this.provService.chats_preview.find(chat => msg.from === chat.nombre);

      if(chat){
        let to_compare = chat.user_chat[chat.user_chat.length - 1];
        to_compare.date = new Date(to_compare.date).toLocaleString();
        to_compare.date = `${to_compare.date.split(',')[0].split('/').reverse().map(num => num < 10 ? '0'+num : num).join('-')}${to_compare.date.split(',')[1]}`;

        // console.log(to_compare, formatted_msg, (to_compare.date !== formatted_msg.date) && (to_compare.mensaje !== formatted_msg.mensaje));
        if((to_compare.date !== formatted_msg.date) && (to_compare.mensaje !== formatted_msg.mensaje)){
          chat.user_chat.push(formatted_msg);
        }
        chat.score = null;
        this.provService.chats.unshift(chat);
      }else{
        this.provService.chats.unshift({
          nombre: msg.from,
          score: null,
          user_chat: [formatted_msg],
        });
      }
      if(preview){
        preview.new++;
      }else{
        this.provService.chats_preview.unshift({
          fecha:formatted_msg.date,
          id: -1,
          mensaje:formatted_msg.mensaje,
          new: 1,
          nombre: chat.nombre,
          active: false
        })
      }
    }

    this.popup_audio.play();

    new Notification(`Nuevo Mensaje de ${msg.from}`, {
      body: msg.body.length > 25 ? msg.body.substr(0, 30)+'...' : msg.body,
      icon: "../../assets/images/salcobrand.svg"
    });
  }

  syncMessages(to_sync){
    // console.log(to_sync);

    let msg_ui = this.messages.find(msg => (msg.id && msg.id === to_sync.id));
    if(msg_ui) to_sync.sync ? msg_ui.customMessageData.sync = true : this.messages.splice(this.messages.indexOf(msg_ui),1);

    const index_obj = this.to_sync_messages.splice(this.to_sync_messages.findIndex(msg => msg.id === to_sync.id),1)[0];

    if(index_obj){
      const chat = this.provService.chats.find(chat => chat.nombre === index_obj.nombre);
      if(to_sync.sync){
        chat.user_chat[index_obj.index].sync = true;
        // console.log(chat);
        //14/12/2022, 13:55:45
        //2022-12-05T15:54:39.000Z
        let chat_raw_date = new Date(chat.user_chat[index_obj.index].date).toLocaleString();
        // console.log(chat_raw_date);
      let mqtt_format_message = {
          from: chat.nombre,
          body: chat.user_chat[index_obj.index].mensaje,
          date: chat_raw_date,
          saliente: true,
          session_id: this.provService.session_id
        }
      this.publishReplyMessage(mqtt_format_message);
      }else{
        chat.user_chat.splice(index_obj.index, 1);
      }
    }

  }

  switchChat(chat){
    if(this.current_chat.nombre === chat.nombre) return;
    let chat_to_find = this.filter_type !== 'Deleted' ? this.provService.chats_preview : this.provService.terminated_chats;
    this.current_chat.active = false;
    let new_chat = chat_to_find.find(c_chat => c_chat.nombre === chat.nombre);
    new_chat.active = true;
    new_chat.new = 0;
    this.current_chat = new_chat;
    this.loadCurrentChat();
  }

  async loadCurrentChat(){
    let chat = this.provService.chats.find(chat => chat.nombre === this.current_chat.nombre);

    if(!chat) {
      chat = await this.provService.getHistoryChat(this.current_chat.nombre.replace('+',''));
      this.provService.chats.push(chat);
    }

    this.messages = chat.user_chat.map(chat => {
      return this.chatUiMessageStructure(chat.mensaje, chat.reply, chat.sync, [], chat.reply ? 'Tu' : this.current_chat.nombre, chat.date, chat.id, chat.destacado);
    });
  }

  async sendMessage(event) {
    if(this.filter_type === 'Deleted') return;

    if(this.provService.chats_preview < 1 || this.messages.length < 1) return;

    let msg_ui_obj = this.chatUiMessageStructure(event.message, true, false, event.files);
    let msg_api_obj = this.chatMessageAPIStructure(event.message, true, false, new Date().toString());

    const id = `id-${Date.now()}-${msg_ui_obj.customMessageData.text}`;

    msg_ui_obj['id'] = id;
    msg_api_obj['id'] = id;

    const to_update_chat_index = this.provService.chats.findIndex((chat:any) => chat.nombre === this.current_chat.nombre);

    this.messages.push(msg_ui_obj);

    this.to_sync_messages.push({
      id: id,
      nombre: this.provService.chats[to_update_chat_index].nombre,
      index: this.provService.chats[to_update_chat_index].user_chat.length
    });

    this.provService.chats[to_update_chat_index].user_chat.push(msg_api_obj);

    this.provService.chats.unshift(this.provService.chats.splice(to_update_chat_index, 1)[0]);

    this.sendMessageToWhatsapp(msg_ui_obj['id'], event.message, this.current_chat.nombre);

  }

  textResume(mensaje){
    return `${mensaje.substr(0, 35)}${mensaje.length > 35 || !mensaje.length? '...' : ''}`
  }

  getFormatTimeStamp(timestamp){
    return (new Date(timestamp).toLocaleTimeString()).split(':').splice(0,2).join(':');
  }

  async openConfirmDialog(){
    if(this.provService.chats.length < 1) return;
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        chat: this.provService.chats.find(chat => chat.nombre === this.current_chat.nombre)
      }
    }).onClose.subscribe(async data => {
      if(data && data === 'Si') {
        try {
          await this.provService.cutChat(this.current_chat.nombre);
          this.provService.chats.splice(this.provService.chats.findIndex(chat => chat.nombre === this.current_chat.nombre),1);
          this.provService.chats_preview.splice(this.provService.chats_preview.findIndex(chat => chat.nombre === this.current_chat.nombre),1);
          if(this.provService.chats_preview.length) {
            this.provService.chats_preview[0].active = true;
            this.current_chat = this.provService.chats_preview[0];
            this.loadCurrentChat();
          }else{
            this.current_chat = this.chatUiMessageStructure('',false, true, [], 'Sin Mensajes', '2022-1-1 00:00:00', -1);
            this.messages = [];
          }
        } catch (error) {
          console.error(error);
        }
      }
    }, error => {console.error(error)})
  }

  filterByName(event){
    let input_value = event.path[0];
    if(event.key === 'Backspace' && (input_value.value === '+' || input_value.value === '')) return input_value.value = '';
    let cleaned_number = input_value.value.replace(/[^0-9]/g, "").trimStart();
    cleaned_number = '+'+cleaned_number;
    input_value.value = cleaned_number;
    this.search_name = input_value.value;
  }

  filterByType(chat){
    let hide = false;
    switch(this.filter_type){
      case "New":
          hide = chat.new === 0 ? true : false
        break;
      default:
        break;
    }
    return hide;
  }

  async filterType(event){
    if(event === 'Deleted'){
      let date = undefined;
      if(this.date_range) {
        if(this.date_range.end){
          date = this.date_range;
        }
      }
      await this.provService.getTerminatedChats(date);
      // console.log(this.provService.terminated_chats);
    }
  }

  subscribeToTopic() {
    console.log('subscribing');

    this.eventMqtt.topic_update = this.eventMqtt.topic('update').subscribe((data: IMqttMessage) => {
      const str = new TextDecoder("utf-8").decode(data.payload);
      const messages = JSON.parse(str);
      console.log(str);
      // this.updateChatsByName(messages);
      this.updateChatsByNumber(messages);
      console.log(messages);
    });

    this.eventMqtt.topic_sync = this.eventMqtt.topic('sync').subscribe((data: IMqttMessage) => {
      const str = new TextDecoder("utf-8").decode(data.payload);
      const messages = JSON.parse(str);
      console.log(messages);
      this.syncMessages(messages);
    });
  }

  sendMessageToWhatsapp(id, message, to){
    let obj_test = {
        id: id,
        to: to.replaceAll('+',''),
        body: message,
      }
      this.eventMqtt.publish('send', JSON.stringify(obj_test)).subscribe(observer => {
        console.log('Publish: '+ observer);
        // console.table(obj_test);
      });
  }

  publishReplyMessage(msg){
    // console.log('PUBLISH',msg);
    this.eventMqtt.publish('update', JSON.stringify(msg)).subscribe(observer => {
      console.log('Publish: '+ observer);
    });
  }

  // sendTestMessage(){
  //   let numbers = ['+56971997123','+56995429364','+56991029348','+56991025422', '+56963270877'];
  //   let mensajes = ['Hola','Probando 123','Otro aleatorio', 'Dragmadul', 'fuertin ha', 'pedro montzz']
  //   setInterval(() => {
  //     let obj_test = {
  //           from: numbers[Math.floor(Math.random() * (4 - 0 + 1) + 0)],
  //           body: mensajes[Math.floor(Math.random() * (5 - 0 + 1) + 0)],
  //           date: "2022-09-27 16:02:05"
  //     }
  //     this.eventMqtt.publish('update', JSON.stringify(obj_test)).subscribe(observer => {
  //       console.log('Publish: '+ observer);
  //     });
  //   }, 750)
  // }

  sendSync(){
    let msg = this.to_sync_messages[0];

    let obj_test = {
        id: msg.id,
        sync: true,
      }

    this.eventMqtt.publish('sync', JSON.stringify(obj_test)).subscribe(observer => {
      console.log('Publish: '+ observer);
    });
  }


  /**
   *
   * @param msg display msg
   * @param reply if you are sending the msg
   * @param sync if the msg is already sent | in database
   * @param archives contain files
   * @param nombre display name | OPTIONAL: 'Tu'
   * @param date dateTime of the message | OPTIONAL: 'Current dateTime'
   * @returns message structured obj ready to use in chat ui
   */
  chatUiMessageStructure(msg:string, reply:boolean, sync:boolean, archives:any, nombre:string = 'Tu', date:string = '', id:any = -1, destacado:any = 0){

    const files = !archives ? [] : archives.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });

    return {
      date: date === '' ? new Date() : new Date(date),
      reply: reply,
      type: files.length ? 'file' : 'sync',
      files: files,
      customMessageData: {
        id: id,
        phone: nombre,
        text: msg,
        date: date === '' ? new Date().toLocaleString() : new Date(date).toLocaleString(),
        reply: reply,
        sync: sync,
        destacado: destacado,
      },
      user: {
        name: nombre,
        avatar: 'https://cdn-icons-png.flaticon.com/512/1383/1383269.png',
      },
    }
  }

  /**
   *
   * @param msg display msg
   * @param reply if you are sending the msg
   * @param sync if the msg is already sent | in database
   * @param date dateTime of the message | OPTIONAL: 'Current dateTime'
   * @returns message structured obj ready to insert on "provService.chats.user_chat"
   */
  chatMessageAPIStructure(message:string, reply:boolean, sync:boolean, date:string){
    return {
      date: date,
      mensaje: message,
      reply: reply,
      sync: sync,
      id: '-1',
    }
  }

  async filter(){
    if(this.date_range) if(!this.date_range.end) return
    await this.provService.getChats(this.date_range);
    await this.provService.getTerminatedChats(this.date_range);
    this.current_chat = this.provService.chats[0] || this.chatUiMessageStructure('',false, true, [], '', '2022-1-1 00:00:00');
    if(this.provService.chats.length) {
      this.provService.chats[0].active = true;
      this.loadCurrentChat();
    }
  }

  async validDateFilter(event){
    if(event.start && event.end){
      setTimeout(async () => {
        await this.filter();
      }, 25);
    }
  }
}