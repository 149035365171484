import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../_services/provider.service';

@Component({
  selector: 'ngx-destacados',
  templateUrl: './destacados.component.html',
  styleUrls: ['./destacados.component.scss']
})
export class DestacadosComponent implements OnInit {

  localList:any = [];
  loader:boolean = false;

  date_range:any = '';
  selectedLocal:any = 'Todos';
  selectedOrigen:any = 'Todos';
  selectedZona:any = 'Todos';
  selectedStatus:any = 'Todos';
  selectedTipoTurno:any = 'Todos';

  categorias:any = [];

  current_tab:string = '';
  msg_list:any = [];
  messages:any = [];

  constructor(public provService : ProviderService){
    this.loader = true;
  }

  async ngOnInit(){
    this.loader = true;
    if(!this.provService.categorias_destacados.length) await this.provService.cargarCategoriasDestacados();
    this.categorias = this.provService.categorias_destacados.map(categoria => {
      return {id: categoria.id, nombre: categoria.nombre, active: false};
    });
    this.categorias[0].active = true;
    this.current_tab = this.categorias[0].nombre;
    await this.fillFiltros();
    await this.filter();
    this.loader = false;
  }


  async fillFiltros(){
    await this.provService.getFiltros();
    this.localList = this.provService.filtros.locales;
  }

  async filter(){
    this.loader = true;
    if(this.date_range) if(!this.date_range.end) return
    this.messages = await this.provService.getMensajesDestacados(this.date_range, this.selectedLocal);
    if(this.current_tab !== ''){
      this.msg_list = this.messages.filter(msg => msg.destacado === this.categorias.find(cat => cat.nombre === this.current_tab)?.id);
    }
    this.loader = false;
  }

  async resetFilters(){
    this.loader = true;
    this.date_range = '';
    this.selectedLocal = 'Todos';
    await this.filter();
    this.loader = false;
  }

  async validDateFilter(event){
    if(event.start && event.end){
      setTimeout(async () => {
        await this.filter();
      }, 25);
    }
  }

  async switchTab(event){
    if(this.current_tab === event.tabTitle) return this.loader = false;
    this.msg_list = this.messages.filter(msg => msg.destacado === this.categorias.find(cat => cat.nombre === event.tabTitle)?.id);
    this.categorias.find(cat => cat.nombre === this.current_tab).active = false;
    this.current_tab = event.tabTitle;
    this.categorias.find(cat => cat.nombre === this.current_tab).active = true;
  }

}
