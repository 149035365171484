<div class="main-container">
    <div class="chats-filter">
        <p>
            CHATS HISTÓRICOS
        </p>
        <div>
            <input style="cursor: pointer;" type="text" class="date-selector" placeholder="Fecha" [nbDatepicker]="formpicker" [(ngModel)]="date_range">
            <nb-rangepicker format="DD/MM/YYYY" #formpicker (rangeChange)="validDateFilter($event)"></nb-rangepicker>
        </div>
    </div>
    <div class="chats-container">
        <div class="side-chats">
            <div class="filter-container">
                <div class="filters">
                    <nb-icon icon="funnel-outline"></nb-icon>
                    <select [(ngModel)]="filter_type" placeholder="Filtrar por" (ngModelChange)="filterType($event)">
                        <option value="Todos">Todos</option>
                        <option value="New">Nuevos Mensajes</option>
                        <option value="Deleted">Mensajes Archivados</option>
                    </select>
                </div>
                <input class="search-bar" (keyup)="filterByName($event)" type="text" maxlength="" placeholder="Busca chat por numero: 56912395175">
            </div>
            <div class="pending-chats">
                <button
                    [ngClass]="{'active': chat.active}"
                    *ngFor="let chat of (filter_type !== 'Deleted' ? provService.chats_preview : provService.terminated_chats)"
                    [hidden]="!chat.active && ((!chat.nombre.includes(search_name) && search_name !== '') || filterByType(chat))"
                    class="p-chat noselect nodrag"
                    (click)="switchChat(chat)"
                >
                    <div class="button">
                        <div>
                            <p class="user-pchat-name">{{chat.nombre}}</p>
                            <p [ngStyle]="{'font-weight': chat.new > 0 ? 'bold' : 'normal'}" class="user-pchat-lastmessage">{{textResume(chat.mensaje)}}</p>
                        </div>
                        <span class="user-pchat-timestamp">{{getFormatTimeStamp(chat.fecha)}}</span>
                    </div>
                    <div *ngIf="chat.new > 0" class="message-bubble">{{chat.new}}</div>
                </button>
            </div>
        </div>
        <div class="chat-container" style="position: relative; max-width: 64vw;">
            <div class="title-overlay">
                <button hidden class="noselect nodrag test-button-2" (click)="sendSync()" ><nb-icon icon="loader-outline"></nb-icon> <span>Sincronizar Mensajes</span></button>
                <!-- <button hidden class="noselect nodrag test-button" (click)="sendTestMessage()" ><nb-icon icon="cloud-upload"></nb-icon> <span>Enviar simulación</span></button> -->
                <button *ngIf="filter_type !== 'Deleted'" class="noselect nodrag" (click)="openConfirmDialog()" ><nb-icon icon="archive-outline"></nb-icon> <span>Archivar conversación</span></button>
            </div>
            <nb-chat status="info" scrollBottom="true" style="border-bottom-right-radius: 5px; border-top-right-radius: 5px;" [title]="current_chat.nombre">
                <div *nbCustomMessage="'sync'; let data" class="sync">
                    <nb-icon class="sync-check" [ngStyle]="{'display': data.reply ? 'initial' : 'none', 'color': data.sync ? 'green' : 'grey'}" [icon]="data.sync ? 'done-all-outline':'checkmark-outline'"></nb-icon>
                    <p style="overflow-wrap: break-word; word-wrap: break-word;" [ngStyle]="{'color': data.reply ? 'black' : 'white'}">{{data.text}}</p>
                    <nb-icon
                        (click)="switchDestacado(data)"
                        [ngClass]="+data.destacado ? 'destacado' : 'no-destacado'"
                        [nbContextMenu]="+data.destacado ? [{title: 'No destacar'}] : highlited_list"
                        nbContextMenuTrigger="click"
                        nbContextMenuPlacement="right"
                        [ngStyle]="{'display': !data.reply ? 'initial' : 'none'}"
                        icon="star"
                        nbContextMenuTag="highlited_menu"
                    >
                    </nb-icon>
                </div>
                <nb-chat-message style="overflow-wrap: break-word; word-wrap: break-word;" class="chat-messages" *ngFor="let msg of messages"
                    [type]="msg.type"
                    [reply]="msg.reply"
                    [sender]="msg.user.name"
                    [date]="msg.date"
                    [files]="msg.files"
                    [quote]="msg.quote"
                    [latitude]="msg.latitude"
                    [longitude]="msg.longitude"
                    [avatar]="msg.user.avatar"
                    [customMessageData]="msg.customMessageData"
                    dateFormat="- dd, MMMM YYYY, HH:mm"
                >
                </nb-chat-message>
                <nb-chat-form (send)="sendMessage($event)" [showButton]="filter_type !== 'Deleted'" [dropFiles]="true"> </nb-chat-form>
            </nb-chat>
        </div>
    </div>
</div>