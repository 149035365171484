import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  @Input() msg_list:any = [];

  categorias:any = {
    1: 'Mala Actitud Vendedor',
    2: 'Precio Elevado',
    3: 'Falta de Stock',
    4: 'Lentitud Atención'
  }

  constructor() { }

  ngOnInit(): void {}

  getCategoria(destacado:any){
    return this.categorias[`${destacado}`];
  }

  getTimeStamp(timeStamp:any){
    if(!timeStamp) return undefined;
    return new Date(timeStamp).toLocaleString().replace(',', '');
  }

  getShortMessage(msg:any){
    return `${msg.substr(0, 75)}${msg.length > 75 ? '...' : ''}`;
  }

  show(msg){
    // console.log(msg);
  }

}
