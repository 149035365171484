import { Component, OnInit } from '@angular/core';
import { NbThemeService, NbLayoutScrollService } from '@nebular/theme';
import { ProviderService } from '../../_services/provider.service';
import { Router } from '@angular/router';
import { stackedChart } from './helpers/stacked_chart';

@Component({
  selector: 'ngx-salcobrand-dashboard',
  templateUrl: './salcobrand-dashboard.component.html',
  styleUrls: ['./salcobrand-dashboard.component.scss']
})
export class SalcobrandDashboardComponent implements OnInit {

  error:boolean = false;

  data_range_selected:any = 'Horas';

  themeSubscription: any;

  optionsBar:any;

  loader:any = false;

  kpiTotalUsers:any = 0;
  kpiMediaUsersDay:any = 0;
  kpiBuildingBaseLine:any = 0;
  kpiFailTests:any = 0;

  bajoRiesgo:any = 0;
  medioBajo:any = 0;
  medioAlto:any = 0;
  altoRiesgo:any = 0;
  failtTest:any = 0;
  totalTests:any = 0;

  show_filters:any = true;
  show_visualization:any = true;
  show_type:any = true;
  show_all:any = true;

  descripcion:any = 'Turnos obtenidos';

  stack_graph_container:HTMLDivElement;

  percentage:any = false;

  virtual_value:any = 5;

  constructor(private theme: NbThemeService, public provService : ProviderService, private router: Router, private scroll: NbLayoutScrollService) {
    this.provService.dashboardComp$.subscribe(res => {
      this.filter()
    });
  }

  async ngOnInit(){
    this.loader = true;
    if(!this.provService.turnos.length) await this.provService.updateData();
    this.provService.callbackEmitter = 'Dashboard';
    this.loadCharts();
    this.loader = false;
  }

  async ngAfterViewInit(){
    this.stack_graph_container = document.getElementById('grafico5') as HTMLDivElement;
  }

  async filter(){
    this.loader = true;
    this.error = false;
    this.descripcion = this.provService.tipo_de_muestra;
    if(!this.provService.turnos.length) {
      this.loader = false;
      this.error = true;
    }
    await this.loadCharts();
    this.loader = false;
    this.scrollTo(this.stack_graph_container.offsetTop - 70);
  }

  async changeDataRange(range_type:any){
    if(this.provService.rango_datos === range_type) return;
    this.provService.rango_datos = range_type;
    await this.updateData();
  }

  async changeVisualization(){
    this.percentage = !this.percentage;
    await this.loadCharts();
  }

  switchFilters(to_show){
    switch(to_show){
      case 'filtros':
        this.show_filters = !this.show_filters;
        break;
      case 'visualizacion':
        this.show_visualization = !this.show_visualization;
        break;
      case 'tipos':
        this.show_type = !this.show_type;
        break;
      case 'todo':
        if(this.show_all){
          this.show_type = false;
          this.show_filters = false;
          this.show_visualization = false;
          this.show_all = !this.show_all;
        }else{
          this.show_type = true;
          this.show_filters = true;
          this.show_visualization = true;
          this.show_all = !this.show_all;
        }
      default: break;
    }
  }

  async updateData(){
    this.loader = true;
    this.error = false;
    this.descripcion = this.provService.tipo_de_muestra;
    await this.provService.updateData();
    if(!this.provService.turnos.length) {
      this.loader = false;
      this.error = true
    }
    await this.loadCharts();
    this.loader = false;
    this.scrollTo(this.stack_graph_container.offsetTop - 70);
  }

  scrollTo(yPos:number){
    this.scroll.scrollTo(0,yPos);
  }

  async exportRawData(){
    this.loader = true;
    const json_data = await this.provService.getRawFilteredData(true);
    // const json_data = await this.provService.excelData(115);
    // json_data.sac = json_data.sac.filter(row =>
    //   row.fono_cliente !== '56963270877' &&
    //   row.fono_cliente !== '56990570447' &&
    //   row.fono_cliente !== '56936319091' &&
    //   row.fono_cliente !== '56993305866' &&
    //   row.fono_cliente !== '56945672622');

    // console.log(json_data);

    // this.provService.exportXLS(json_data.sac, 'local '+json_data.codigo_local,'local '+json_data.codigo_local);
    this.provService.exportXLS(json_data, 'turnos', 'turnos');
    this.loader = false;
  }

  async exportRawFilteredData(){
    this.loader = true;
    const json_data = await this.provService.getRawFilteredData(true);
    console.log(json_data);
    this.provService.exportXLS(json_data, '', `turnos en ${this.provService.rango_datos}`, true);
    this.loader = false;
  }

  async loadCharts(){
    console.log(this.provService);
    let stack_obj = {
      names: ['No hay data para mostrar'],
      xAxis: ['Sin datos'],
      yAxis: [{name: 'Sin datos', values: 0}],
    }

      if((this.provService.tipo_de_muestra === 'Respuestas SAC' || this.provService.tipo_de_muestra === 'Tiempo promedio de espera' || this.provService.tipo_de_muestra === 'Tiempo promedio de atención' || this.provService.tipo_de_muestra === 'Clientes en fila' || !this.provService.turnos.length) && this.percentage) this.percentage = false
      if((this.provService.tipo_de_muestra === 'Puntaje NPS' || this.provService.tipo_de_muestra === 'Tasa de respuesta NPS') && !this.percentage) this.percentage = true;

      if(this.provService.turnos.length){

        stack_obj.names = Object.keys(this.provService.turnos[0]).filter(key => key.includes('('));

        switch(this.provService.rango_datos){
          case 'Local':
            console.log(this.provService.turnos);
            stack_obj.xAxis = this.provService.turnos.map(turno => {
              return turno.Local.replace('Salcobrand ', '');
            })
            break;
            case 'Horas':
            stack_obj.xAxis = this.provService.turnos.map(turno => {
              const hour = new Date(turno.hora_ingreso).getHours().toString();
              return `${hour.length === 1 ? '0' : ''}${hour}:00`;
            })
            break;
          case 'Dias':
            stack_obj.xAxis = this.provService.turnos.map(turno => {
              return new Date(turno.hora_ingreso).toLocaleDateString();
            });
            break;
          case 'Semanas':
            stack_obj.xAxis = this.provService.turnos.map(turno => {
              return turno.Semanas;
            });
            break;
          case 'Mes':
            stack_obj.xAxis = this.provService.turnos.map(turno => {
              return new Date(turno.hora_ingreso).toLocaleDateString().split('/').splice(1,2).join('/');
            });
            break;
        }
        stack_obj.yAxis = stack_obj.names.map(name => {
          let values = this.provService.turnos.map(turno => {
            let total_turnos = Object.keys(turno).reduce((acc, current) => {
              if(stack_obj.names.includes(current)) acc += turno[current];
              return acc;
            },0);
            let to_return;
            if(this.percentage){
              if(this.provService.tipo_de_muestra === 'Puntaje NPS' || this.provService.tipo_de_muestra === 'Tasa de respuesta NPS'){
                to_return = turno[`${name}`].toFixed(2);
              }else if (this.provService.tipo_de_muestra === 'Canal obtención turnos'){
                to_return = ((turno[`${name}`] * 100) / total_turnos).toFixed(2);
              }else{
                to_return = Math.round((turno[`${name}`] * 100) / total_turnos);
              }
            }else{
              to_return = turno[`${name}`];
            }
            return to_return;
          })
          return {
            name: name,
            values: values,
          }
        })
      }
      console.log(stack_obj);
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.optionsBar = stackedChart(stack_obj, this.percentage);
    });
  }

}