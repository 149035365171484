import {
  Component,
  Input,
  HostBinding,
  ViewChild,
  ElementRef,
  ContentChildren,
  QueryList,
  AfterViewInit,
  ContentChild,
  SimpleChanges,
  AfterContentInit,
  OnChanges,
} from '@angular/core';
import { NbChatFormComponent, NbComponentSize, NbComponentStatus } from '@nebular/theme';
import { ChatUiComponent } from '../chat-message/chat-message.component';
import { convertToBoolProperty } from '../helper/helpers';

@Component({
  selector: 'ngx-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnChanges, AfterContentInit, AfterViewInit {

  @Input() title: string;

  /**
   * Chat size, available sizes:
   * `tiny`, `small`, `medium`, `large`, `giant`
   */
  @Input() size: NbComponentSize;

  /**
   * Chat status color (adds specific styles):
   * `primary`, `success`, `info`, `warning`, `danger`
   */
  @Input() status: NbComponentStatus;

  /**
   * Scroll chat to the bottom of the list when a new message arrives
   */
  @Input()
  get scrollBottom(): boolean {
    return this._scrollBottom
  }
  set scrollBottom(value: boolean) {
    this._scrollBottom = convertToBoolProperty(value);
  }
  protected _scrollBottom: boolean = true;

  @ViewChild('scrollable', { static: false }) scrollable: ElementRef;
  @ContentChildren(ChatUiComponent) messages: QueryList<ChatUiComponent>;
  @ContentChild(NbChatFormComponent, { static: false }) chatForm: NbChatFormComponent;

  ngOnChanges(changes: SimpleChanges) {
    if ('status' in changes) {
      this.updateFormStatus();
    }
  }

  ngAfterContentInit() {
    this.updateFormStatus();
  }

  ngAfterViewInit() {
    this.messages.changes
      .subscribe((messages) => {
        this.messages = messages;
        this.updateView();
      });

    this.updateView();
  }

  updateView() {
    if (this.scrollBottom) {
      this.scrollListBottom();
    }
  }

  scrollListBottom() {
    this.scrollable.nativeElement.scrollTop = this.scrollable.nativeElement.scrollHeight;
  }

  protected updateFormStatus(): void {
    if (this.chatForm) {
      this.chatForm.setStatus(this.status);
    }
  }

  @HostBinding('class.size-tiny')
  get tiny(): boolean {
    return this.size === 'tiny';
  }

  @HostBinding('class.size-small')
  get small(): boolean {
    return this.size === 'small';
  }

  @HostBinding('class.size-medium')
  get medium(): boolean {
    return this.size === 'medium';
  }

  @HostBinding('class.size-large')
  get large(): boolean {
    return this.size === 'large';
  }

  @HostBinding('class.size-giant')
  get giant(): boolean {
    return this.size === 'giant';
  }

  @HostBinding('class.status-primary')
  get primary(): boolean {
    return this.status === 'primary';
  }

  @HostBinding('class.status-success')
  get success(): boolean {
    return this.status === 'success';
  }

  @HostBinding('class.status-info')
  get info(): boolean {
    return this.status === 'info';
  }

  @HostBinding('class.status-warning')
  get warning(): boolean {
    return this.status === 'warning';
  }

  @HostBinding('class.status-danger')
  get danger(): boolean {
    return this.status === 'danger';
  }
}