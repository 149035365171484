<ngx-custom-spinner
    *ngIf="loader"
    class="router-child-adjustment"
></ngx-custom-spinner>
<div class="main-wrapper">
    <div class="resultsGraficos" style="min-height: 700px; position: relative;">
        <div class="locales-container">
            <div
                *ngFor="let local of locales"
                class="box"
                >
                <div class="title-card">
                    <h5>{{local.nombre_local}}</h5>
                    <div class="codigo"><p>{{local.codigo}}</p></div>
                </div>
                <div class="body-card" [ngStyle]="{'background-color': stateColor(local)}">
                    <div>
                        <p>Cant. Max en fila</p>
                        <p>{{local.en_fila}} Personas</p>
                    </div>
                    <div>
                        <p>Espera Promedio</p>
                        <p>{{local.espera_promedio}} Minutos</p>
                    </div>
                    <div>
                        <p>Espera Maxima</p>
                        <p>{{local.espera_maxima}} Minutos</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu">
            <div>
                <h3><nb-icon icon="clock-outline"></nb-icon>{{time_left}}</h3>
                <p>Ultima actualización <strong>{{last_update}}</strong></p>
            </div>
            <p style="position: absolute; right: 20px; bottom: 5px; font-weight: bold; color: gray;">* En base a ventana móvil de 30 minutos</p>
        </div>
    </div>
</div>