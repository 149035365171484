<nb-accordion style="max-height: 67vh; overflow-y: auto; max-width: 90vw;" class="noselect nodrag">
    <nb-accordion-item *ngFor="let msg of msg_list" class="noselect nodrag">
        <nb-accordion-item-header>
            <nb-badge text="{{getCategoria(msg.destacado)}}" status="warning" position="top right"(click)="show(msg)"></nb-badge>
            <nb-badge text="Local {{msg.codigo_local || 'No hay registro'}}" status="primary" position="top left"></nb-badge>
            <div class="header">
                <p>{{getTimeStamp(msg.fecha_mensaje)}}</p>
                <p>{{getShortMessage(msg.mensaje)}}</p>
            </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
            <div class="content">
                <div>
                    <p>Mensaje</p>
                    <p style="font-style: italic;">{{'❝ '+msg.mensaje+' ❞'}}</p>
                </div>
                <div>
                    <p>Fecha y Hora mensaje</p>
                    <p>{{getTimeStamp(msg.fecha_mensaje)}}</p>
                </div>
                <div>
                    <p>Fecha y Hora Turno</p>
                    <p>{{getTimeStamp(msg.hora_atencion) || 'No hay registro'}}</p>
                </div>
                <div>
                    <p>Turno</p>
                    <p>{{msg.turno_label || 'No hay registro'}}</p>
                </div>
                <div>
                    <p>Personas en espera al sacar turno</p>
                    <p>{{msg.personas_en_fila ? msg.personas_en_fila+' '+ (msg.personas_en_fila > 1? 'Personas' : 'Persona') : 'No hay registro'}}</p>
                </div>
                <div>
                    <p>Tiempo esperado</p>
                    <p>{{msg.tiempo_espera ? msg.tiempo_espera+' '+ (msg.tiempo_espera > 1? 'Minutos' : 'Minuto') : 'No hay registro'}}</p>
                </div>
                <div>
                    <p>Tiempo de atención</p>
                    <p>{{msg.tiempo_atencion ? msg.tiempo_atencion+' '+ (msg.tiempo_atencion > 1? 'Minutos' : 'Minuto') : 'No hay registro'}}</p>
                </div>
            </div>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>