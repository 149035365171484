<nb-card style="width: 500px; height: px;">
    <nb-card-header>
        Confirmación de archivación de chat
    </nb-card-header>
    <nb-card-body>
        <p>Esta segura/o que desea archivar el chat con <strong>{{chat.nombre}}</strong> y sus <strong>{{chat.user_chat.length}}</strong> mensajes?.</p>
        <p class="quote">Le recordamos que una vez archivados estos mensajes no se volverán a mostrar y solo podrá visualizar nuevas conversaciones con el numero seleccionado.</p>
    </nb-card-body>
    <nb-card-footer>
        <button (click)="dismiss('Si')">Si</button>
        <button (click)="dismiss('No')">No</button>
    </nb-card-footer>
</nb-card>