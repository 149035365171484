<ngx-custom-spinner
    *ngIf="loader"
    class="router-child-adjustment"
></ngx-custom-spinner>
<div class="main-container">
    <div class="filtros">
        <div>
            <nb-icon nbTooltip="Limpiar filtros" nbTooltipStatus="#203a5f22" nbTooltipPlacement="bottom" (click)="resetFilters()" icon="funnel-outline"></nb-icon>
        </div>
        <ul>
            <li class="dateSelector">
                <p>Rango de Fechas</p>
                <input style="cursor: pointer;" type="text" class="iconicInput icon-date" nbInput placeholder="Fecha" [nbDatepicker]="formpicker" [(ngModel)]="date_range">
                <nb-rangepicker format="DD/MM/YYYY" #formpicker (rangeChange)="validDateFilter($event)"></nb-rangepicker>
            </li>
            <li>
                <p>Locales</p>
                <select [(ngModel)]="selectedLocal" placeholder="Locales" class="iconicInput icon-base" (change)="filter()">
                    <option value="Todos">Todos</option>
                    <option *ngFor="let local of localList" value="{{local}}">{{local}}</option>
                </select>
            </li>
        </ul>
    </div>
    <div>
        <nb-tabset (changeTab)="switchTab($event)" style="max-width: 85vw;" class="noselect nodrag">
            <nb-tab class="noselect nodrag" *ngFor="let categoria of categorias" style="padding: 0;" [tabTitle]="categoria.nombre" [active]="categoria.active">
                <ngx-tab [msg_list]="msg_list"></ngx-tab>
            </nb-tab>
            <!-- <nb-tab style="padding: 0;" tabTitle="Precio Elevado">
                <ngx-tab [msg_list]="msg_list"></ngx-tab>
            </nb-tab>
            <nb-tab style="padding: 0;" tabTitle="Falta de Stock">
                <ngx-tab [msg_list]="msg_list"></ngx-tab>
            </nb-tab>
            <nb-tab style="padding: 0;" tabTitle="Lentitud Atención">
                <ngx-tab [msg_list]="msg_list"></ngx-tab>
            </nb-tab> -->
        </nb-tabset>
    </div>
</div>