import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../_services/provider.service';
import * as moment from 'moment';
import { NbThemeService } from '@nebular/theme';
import { stackedChart } from './helpers/stacked_chart';

@Component({
  selector: 'ngx-tiempo-modulo',
  templateUrl: './tiempo-modulo.component.html',
  styleUrls: ['./tiempo-modulo.component.scss']
})
export class TiempoModuloComponent implements OnInit {

  loader:any = false;
  selectedMes:any = (new Date()).getMonth() + 1;
  selectedYear: any = (new Date()).getFullYear();
  selectedDireccion = 2;
  selectedDay = 0;
  selectedFila = 1;
  selectedDesde = 0;
  selectedHasta = 0;
  selectedOpcion = 1;
  years = []
  months = [
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
  ]
  days = []
  direcciones = [
    { label: 'Dirección del Tránsito Municipalidad', value: 2 },
    // { label: 'Dirección del Tránsito Los Ingleses', value: 3 }
  ]
  horas = [
    { label: 'Todas', value: 0 },
    { label: '8:00', value: 8 },
    { label: '9:00', value: 9 },
    { label: '10:00', value: 10 },
    { label: '11:00', value: 11 },
    { label: '12:00', value: 12 },
    { label: '13:00', value: 13 },
    { label: '14:00', value: 14 },
    { label: '15:00', value: 15 },
    { label: '16:00', value: 16 },
    { label: '17:00', value: 17 },
    { label: '18:00', value: 18 },
  ]
  filas = [
    { label: 'Renovación Licencia', value: 1 },
    { label: 'Primera Licencia', value: 2 }    
  ]

  opciones = [
    {label: 'Tiempo promedio atención', value: 1},
    {label: 'Cantidad atendidos', value: 2},
  ]

  grafica:any = []

  percentage:any = false;
  themeSubscription: any;
  optionsBar:any;
  optionsBar2:any;

  constructor(public providerService: ProviderService, private theme: NbThemeService) { }

  async ngOnInit(){
    this.loader = true;
    
    const yearsActual = [];

    const actualYear = (new Date()).getFullYear();

    for (let i = 2022; i <= actualYear; i++) {
      yearsActual.push({ label: i, value: i });      
    }

    this.years = yearsActual;

    this.obtenerDiasMes();

    this.loader = false;

    await this.filtro();
    
    this.graficoTiempo();
  }

  async filtro() {

    if ( this.selectedOpcion == 1 ) {

      let data: any = [];

      if ( this.selectedDay == 0 ) {
        await this.providerService.getModuloMes(this.selectedMes, this.selectedYear, this.selectedDireccion, this.selectedFila == 1 ? 41 : 42);
        data = await this.providerService.getModuloEstadistica;

      } else if (this.selectedDesde == 0) {
        await this.providerService.getModuloDia(this.selectedMes, this.selectedYear, this.selectedDireccion, this.selectedDay, this.selectedFila == 1 ? 41 : 42);
        data = await this.providerService.getModuloEstadistica;
        
      } else {
        await this.providerService.getModuloHora(this.selectedMes, this.selectedYear, this.selectedDireccion, this.selectedDay, this.selectedDesde, this.selectedHasta, this.selectedFila == 1 ? 41 : 42);
        data = await this.providerService.getModuloEstadistica;

      }

      this.grafica = data;

      this.graficoTiempo();
    } else {
      let data: any = [];

      if ( this.selectedDay == 0 ) {
        await this.providerService.getModuloCantidadMes(this.selectedMes, this.selectedYear, this.selectedDireccion, this.selectedFila == 1 ? 41 : 42);
        data = await this.providerService.getModuloEstadistica;

      } else if (this.selectedDesde == 0) {
        await this.providerService.getModuloCantidadDia(this.selectedMes, this.selectedYear, this.selectedDireccion, this.selectedDay, this.selectedFila == 1 ? 41 : 42);
        data = await this.providerService.getModuloEstadistica;
        
      } else {
        await this.providerService.getModuloCantidadHora(this.selectedMes, this.selectedYear, this.selectedDireccion, this.selectedDay, this.selectedDesde, this.selectedHasta, this.selectedFila == 1 ? 41 : 42);
        data = await this.providerService.getModuloEstadistica;

      }
      console.log(data)
      this.grafica = data;

      this.graficoCantidad();
    }
  }

  formatearFecha(fecha) {
    return moment(fecha).format("DD-MM-YYYY HH:mm")
  }

  formatearRut(rut) {
     
    var actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
            var letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;
            if (j % 3 == 0 && j <= inicio.length - 1) {
                rutPuntos = "." + rutPuntos;
            }
            j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
  }

  obtenerDiasMes() {
    this.selectedDay = 0;
    this.selectedDesde = 0;
    this.selectedHasta = 0;

    const dias = [];
    const mes = (this.selectedMes - 1);
    const fecha = new Date(this.selectedYear, mes, 1);
  
    dias.push({ label: 'Todos', value: '0' });
    
    let contador = 1;

    while (fecha.getMonth() === mes) {
      dias.push({ label: contador, value: contador });
      fecha.setDate(fecha.getDate() + 1);
      contador++;
    }
  
    this.days = dias;
  }

  changeDays() {
    this.selectedDesde = 0;
    this.selectedHasta = 0;
  }

  changeDesde () {
    this.selectedHasta = 0;
  }

  filtroReinicio () {
    this.selectedMes = (new Date()).getMonth() + 1;
    this.selectedYear = (new Date()).getFullYear();
    this.obtenerDiasMes();
  }

  async graficoTiempo(){
    
    let stack_obj = {
      names: ['No hay data para mostrar'],
      xAxis: ['Sin datos'],
      yAxis: [{name: 'Sin datos', values: 0}],
    }
    
    if(this.grafica.length){

      stack_obj.names = ['Modulo 3', 'Modulo 4', 'Modulo 5', 'Emisión Antecedentes', 'Examen Medico', 'Examen Psicotecnico', 'Examen Teorico', 'Digitacion y Entrega'];

      stack_obj.xAxis = stack_obj.names;

      stack_obj.yAxis = stack_obj.names.map(name => {

        let total = 0;
        const existe = this.grafica.find( modulo => {
          
          if (modulo.nombre == `${name}`) return modulo
        });
        
        if ( existe ) {
          total = (existe.tiempoPromedio);
        }
        
        return {
          name: name,
          values: total
        };
      })

    }
      
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.optionsBar = stackedChart(stack_obj, this.percentage, '#7ec0ef', 'Minutos por persona');
    });
  }

  async graficoCantidad(){
    
    let stack_obj = {
      names: ['No hay data para mostrar'],
      xAxis: ['Sin datos'],
      yAxis: [{name: 'Sin datos', values: 0}],
    }
    
    if(this.grafica.length){

      stack_obj.names = ['Modulo 3', 'Modulo 4', 'Modulo 5', 'Emisión Antecedentes', 'Examen Medico', 'Examen Psicotecnico', 'Examen Teorico', 'Digitacion y Entrega'];

      stack_obj.xAxis = stack_obj.names;

      stack_obj.yAxis = stack_obj.names.map(name => {

        let total = 0;
        const existe = this.grafica.find( modulo => {
          
          if (modulo.nombre == `${name}`) return modulo
        });
        
        if ( existe ) {
          total = (existe.cantidad);
        }
        
        return {
          name: name,
          values: total
        };
      })

    }
      
    this.themeSubscription = this.theme.getJsTheme().subscribe(config => {

      this.optionsBar = stackedChart(stack_obj, this.percentage, '#2ECC71', 'Cantidad Atendidos');
    });
  }

  async exportarXLSRenovar () {

    const modulosKey = ['Modulo 3', 'Modulo 4', 'Modulo 5', 'Emisión Antecedentes', 'Examen Medico', 'Examen Psicotecnico', 'Examen Teorico', 'Digitacion y Entrega'];

    const data = modulosKey.map( moduloKey => {
      const nuevo = {
        nombre: '',
        tiempoPromedio: 0
      }

      const existe = this.grafica.find( modulo =>{
        if ( modulo.nombre === moduloKey ) {
          return modulo;
        }
      })

      nuevo.nombre = existe ? existe.nombre : moduloKey;
      nuevo.tiempoPromedio = existe ? existe.tiempoPromedio : 0;
      return nuevo
    });
    
    this.providerService.exportXLSFilaMes(data, 'Tiempo Atención por Modulo', `Tiempo por persona de "${this.direcciones[0].label}" en "Renovar Licencia" ${this.months[this.selectedMes - 1].label} ${this.selectedYear}`);
  }
}
