import { Component, OnInit } from '@angular/core';
import { ProviderService } from '../../_services/provider.service';
import * as moment from 'moment';

@Component({
  selector: 'ngx-tiempo-persona',
  templateUrl: './tiempo-persona.component.html',
  styleUrls: ['./tiempo-persona.component.scss']
})
export class TiempoPersonaComponent implements OnInit {

  loader:any = false;
  selectedMes:any = (new Date()).getMonth() + 1;
  selectedYear: any = (new Date()).getFullYear();
  selectedDireccion = 1;
  selectedFila = 1;
  years = []
  months = [
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 },
  ]
  direcciones = [
    { label: 'Dirección del Tránsito Municipalidad', value: 1 },
    // { label: 'Dirección del Tránsito Los Ingleses', value: 2 }
  ]
  filas = [
    { label: 'Renovación Licencia', value: 1 },
    { label: 'Primera Licencia', value: 2 }    
  ]
  
  data:any = []

  constructor(public providerService: ProviderService) { }

  async ngOnInit(){
    this.loader = true;
    
    const yearsActual = [];

    const actualYear = (new Date()).getFullYear();

    for (let i = 2022; i <= actualYear; i++) {
      yearsActual.push({ label: i, value: i });      
    }

    this.years = yearsActual;

    this.loader = false;

    this.filtro();
  }

  async filtro() {
    const fila = this.selectedDireccion == 1 ? (this.selectedFila == 1 ? 41 : 42) : (this.selectedFila == 1 ? 45 : 46);

    this.data = await this.providerService.getGenteFilaMes(this.selectedMes,this.selectedYear, fila);
  }

  formatearFecha(fecha) {
    return moment(fecha).format("DD-MM-YYYY HH:mm")
  }

  formatearRut(rut) {
     
    var actual = rut.replace(/^0+/, "");
    if (actual != '' && actual.length > 1) {
        var sinPuntos = actual.replace(/\./g, "");
        var actualLimpio = sinPuntos.replace(/-/g, "");
        var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
        var rutPuntos = "";
        var i = 0;
        var j = 1;
        for (i = inicio.length - 1; i >= 0; i--) {
            var letra = inicio.charAt(i);
            rutPuntos = letra + rutPuntos;
            if (j % 3 == 0 && j <= inicio.length - 1) {
                rutPuntos = "." + rutPuntos;
            }
            j++;
        }
        var dv = actualLimpio.substring(actualLimpio.length - 1);
        rutPuntos = rutPuntos + "-" + dv;
    }
    return rutPuntos;
  }

  async exportarXLS () {

    const data = this.data.map( vecino => {
      const nuevoVecino = {
        nombre: '',
        RUT: '',
        hora_inicio_atencion: '',
        hora_termino_atencion: '',
        tiempoTramite: '',
        Departamento: '',
        Tipo: '',
        ultima_atencion: ''
      };
      nuevoVecino.nombre = vecino.nombre;
      nuevoVecino.RUT = vecino.identificador_cliente ? this.formatearRut(vecino.identificador_cliente) : 'No registra';
      nuevoVecino.hora_inicio_atencion = this.formatearFecha(vecino.hora_ingreso);
      nuevoVecino.hora_termino_atencion = this.formatearFecha(vecino.hora_termino_atencion);
      nuevoVecino.tiempoTramite = `${vecino.tiempoPromedio} Minutos`;
      nuevoVecino.ultima_atencion = vecino.modulo;
      nuevoVecino.Departamento = vecino.local;
      nuevoVecino.Tipo = vecino.fila_nombre;
      return nuevoVecino;
    });

    this.providerService.exportXLSFilaMes(data, 'Tiempo Personas', `Tiempo por persona de "${this.direcciones[this.selectedDireccion-1].label}" en "${this.filas[this.selectedFila - 1].label}" ${this.months[this.selectedMes - 1].label} ${this.selectedYear}`);
  }
}
