<div class="filters">
    <div class="filters-container">
        <!--<img nbTooltip="Limpiar filtros" nbTooltipStatus="#203a5f22" nbTooltipPlacement="bottom" (click)="resetFilters()" src="assets/images/funnel-outline.svg" class="filtericon"/>-->
        <ul>
            <li class="dateSelector">
                <p>Rango de Fechas</p>
                <input style="cursor: pointer;" type="text" class="iconicInput icon-date" nbInput placeholder="Fecha" [nbDatepicker]="formpicker" [(ngModel)]="date_range">
                <nb-rangepicker format="DD/MM/YYYY" #formpicker (rangeChange)="validDateFilter($event)"></nb-rangepicker>
            </li>
            <li>
                <p>Locales</p>
                <select [(ngModel)]="selectedLocal" placeholder="Locales" class="iconicInput icon-base" (change)="filter()">
                    <option *ngFor="let local of localList" value="{{local}}">{{local}}</option>
                </select>
            </li>
            <li>
                <p>Origenes</p>
                <select [(ngModel)]="selectedOrigen" placeholder="Origenes" class="iconicInput icon-base" (change)="filter()">
                    <option value="Todos">Todos</option>
                    <option *ngFor="let origen of origenList" value="{{origen}}">{{origen.split(' (')[0]}}</option>
                </select>
            </li>
            <li>
                <p>Status</p>
                <select [(ngModel)]="selectedStatus" placeholder="Status" class="iconicInput icon-base" (change)="filter()">
                    <option value="Todos">Todos</option>
                    <option *ngFor="let status of statusList" value="{{status}}">{{status.split(' (')[0]}}</option>
                </select>
            </li>
            <!-- <li>
                <p>Tipos de turnos</p>
                <select [(ngModel)]="selectedTipoTurno" placeholder="Tipo" class="iconicInput icon-base" (change)="filter()">
                    <option value="Todos">Todos</option>
                    <option *ngFor="let tipo_turno of tipoTurnoList" value="{{tipo_turno}}">{{tipo_turno.split(tipo_turno.substr(-4,4))[0]}}</option>
                </select>
            </li> -->
        </ul>
    </div>
</div>